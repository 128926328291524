// import { toAbsoluteUrl } from '../../../../_start/helpers'
// import { useEffect, useState } from "react";
import log from "../../assets/profile-img/user.png"
import { useTranslation } from "react-i18next";
// import axios from "axios";


// const Donation =[
//     {
//         "payee":"Salman farsi",
//         "amount":"$1000",
//         "time":"8"
//     },
//     {
//         "payee":"Muhammed Faisal",
//         "amount":"$1550",
//         "time":"8"
//     },
//     {
//         "payee":"Is-haq Ahmed",
//         "amount":"$150",
//         "time":"8"
//     },
//     {
//         "payee":"Syed Rahamathullah",
//         "amount":"$2100",
//         "time":"8"
//     },
// ]

type Props = {
  id: any;
  donation: any;
  currencySymbol: any;
}

const DonCard: React.FC<Props> = ({id, donation, currencySymbol}) => {
  const [t]=useTranslation("global");
  // const [donation, setDonation] = useState([]);

  // useEffect(() => {
  //   const fetchDonorsData = async () => {
  //     console.log("Fundraiser ID - Donation: ", id);
  //     try {
  //       const username = 'mfb_apikey';
  //       const apiKey = localStorage.getItem("apiKey");
  //       const headers = {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
  //       };
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/api/donors/${id}`,
  //         { headers: headers }
  //       );
  //       // const fundraiser = response.data.object.data[0];
  //       setDonation(response.data.data);
  //       console.log(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching donor details:", error);
  //     }
  //   };

  //   fetchDonorsData();
  // }, [id]);

  const removeTimeFromDate = (dateTime: string): string => {
    const date = new Date(dateTime);
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="card detail-shadow py-2">
    <div className="venue-detail px-3 px-md-8">
      <h5 className="venue-title">{t("recent")}</h5>
      <div className="free-tick ">

        {donation.map((e)=>(
        <div className="donations d-flex align-items-center py-2">
          <div className="donation-logo me-3">
              <img src={log} alt="" />

          </div>
          <div className="donation-list">
            <h3 className="payee-name mb-0">{e.name}</h3>
            <p className="mb-0">
              <span className="paided">{currencySymbol}{Number(e.amount).toFixed(2)}</span>
              <span className="paid-date ms-3">{removeTimeFromDate(e.transactiondate)}</span>
            </p>
          </div>
        </div>
        ))}  
       
      </div>
    </div>
  </div>
  )
}

export default DonCard