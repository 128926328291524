import React, { useState ,useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import CreateFundraiserForm1 from "./CreateFundraiserForm1";
import CreateFundraiserForm2 from "./CreateFundraiserForm2";
import CreateFundraiserForm3 from "./CreateFundraiserForm3";
import CreateFundraiserSuccess from "./CreateFundraiserSuccess";
import AzureStorageService from "../../../azure/AzureStorage";
// import { BlobServiceClient } from "@azure/storage-blob";
import log from '../../../logger';

type Props = {
  show: boolean;
  setShow: any;
};

const CreateFundraiserModal: React.FC<Props> = ({ show, setShow }) => {

  const [showForm1, setShowForm1] = useState(true);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [showSuccess ,setShowSuccess] = useState(false);
  const [formData, setFormData] = useState<any>({
    // targetAmount: 0,
    // prodName: "",
    // videoUrl: "",
    // description: "",
    // organizationId: 1193
  });
  const [error, setError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [prodNameError, setProdNameError] = useState(null);
  const [storyError, setStoryError] = useState(null);
  const [imageError, setImageError] = useState<string | null>(null);

  const [id, setId] = useState();
  const [image, setImage] = useState([]);
  const azureStorageService = new AzureStorageService();

  const orgId = localStorage.getItem("OrganizationId");

  const handleClose=() => {
    setShow(false);
    resetForm();
    // handleForm1();
  };

  //1753 - 21 After creating the cause and click the x mark and again click the create fundraising button the same pop is showing

  const resetForm = useCallback(() => {
    setFormData({
      targetAmount: '',
      prodName: '',
      videoUrl: '',
      description: '',
      organizationId: orgId || '',
    });
    setError(null);
    setUrlError(null);
    setProdNameError(null);
    setStoryError(null);
    setImageError(null);
    setShowForm1(true);
    setShowForm2(false);
    setShowForm3(false);
    setShowSuccess(false);

    setImage([]);
  }, [orgId]);


  useEffect(()=> {
    if(show) {
      resetForm();
    }
  }, [show, resetForm]);

  
 // 1753 - 46 In Create Fundraiser In video Tab image Link was Accepting
  const isValidUrl = (urlString: string): boolean => {
    try {
      const url = new URL(urlString);
      const videoExtensions = /\.(mp4|webm|ogg)$/i;
      const validVideoHostnames = ['youtube.com', 'youtu.be', 'vimeo.com'];
      return (
        (url.protocol && url.hostname) &&
        (videoExtensions.test(url.pathname) || validVideoHostnames.includes(url.hostname))
      );
    } catch (error) {
      return false;
    }
  };

  // 1753 - 47 After creating the cause, if we click the 'X' mark to close the page and then click the 'Create Fundraising' button again, it directly navigates back to the same page that was closed
  const handleForm1 = () => {
    setShowForm1(true);
    setShowForm2(false);
    setShowForm3(false);
  }

  const handleForm2 = () => {
    if(formData.targetAmount) {
      setError(null);
      if(formData.targetAmount<=0) {
        setError("Please enter a valid amount.");
        return;
      }
      setShowForm1(false);
      setShowForm2(true);
      setShowForm3(false);
      // if(formData.targetAmount<99999.99) {
      //   setShowForm1(false);
      //   setShowForm2(true);
      //   setShowForm3(false);
      // }else {
      //   setError("Please enter an amount within 99999.99");
      // }
      
    }else {
      setError("Please enter the starting goal.");
    }
  }

  const handleForm3 = () => {
    if(formData.videoUrl && !isValidUrl(formData.videoUrl)) {
      setUrlError("Please enter a valid video url.");
      return;
    }
    if(image[0] && formData.prodName && formData.description && formData.prodName.trim(" ").length>0 && formData.description.trim(" ").length>0) {
      setShowForm1(false);
      setShowForm2(false);
      setShowForm3(true);
    }else {
      if(!image[0]) {
        setImageError("Please select an image.");
      }      
      if(!formData.description || formData.description.trim(" ").length===0){
        setStoryError("Please enter a story for the fundraiser.");
      }
      if(!formData.prodName || formData.prodName.trim(" ").length===0){
        setProdNameError("Please enter a title for the fundraiser.");
      }
    }
  }

  const uploadImageToAzure = async (id:number) => {
    try {
      const formData = new FormData();
      formData.append('image', image[0].file);
      console.log("Azure cover id: ",id);
      
      // const blobServiceClient = new BlobServiceClient("DefaultEndpointsProtocol=https;AccountName=myfundboxcrowdfunding;AccountKey=tyTQ7PXFYIezCbqYgf6rww51y05AkA+BuVQGxAwqUzAqZt+eU31hYO+3YK2WYImwUn3NOD+teEIn+AStNDke5w==;EndpointSuffix=core.windows.net");
      // const blobServiceClient = new BlobServiceClient("https://myfundboxcrowdfunding.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-04-02T15:17:32Z&st=2024-04-02T07:17:32Z&spr=https&sig=smePmVKaB5BZSzFaDEMbJAWQeULaUaXr9G0Mbgqfu68%3D");
      // console.log(blobServiceClient);
      
      // const containerClient = blobServiceClient.getContainerClient("cover");
      // const blockBlobClient = containerClient.getBlockBlobClient(`${id}`);
      // await blockBlobClient.uploadData(image[0].file);

      azureStorageService.uploadCoverImage(image[0].file,id);
      log.info('Uploaded cover image to azure for fundraiser - ', id)

      // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fundraiser/save-image?id=${id}`, {
      //   method: 'POST',
      //   body: formData,
      // });

      // if (response.ok) {
      //   const imageUrl = await response.text();
      //   console.log('Image uploaded to Azure:', imageUrl);
      // } else {
      //   console.error('Failed to upload image to Azure');
      // }
    } catch (error) {
      log.error('Error uploading image to Azure:', error);
    }
  };

  const handleSuccess = () => {
    setShowForm3(false);
    // setShowSuccess(true);
    console.log(formData);

    // const orgId = localStorage.getItem("organizationId");
    
    const queryParams = new URLSearchParams({
      targetAmount: String(formData.targetAmount),
      prodName: String(formData.prodName),
      videoUrl: String(formData.videoUrl),
      description: String(formData.description),
      organizationId: String(orgId)
    });

    const username = 'mfb_apikey';
    const apiKey = localStorage.getItem("apiKey");

    fetch(`${process.env.REACT_APP_API_URL}/api/fundraiser/create?${queryParams}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
      }
    })
    .then(response => {
      if (response.ok) {
        // Handle success
        
        return response.json(); // Parse the response body as JSON
      } else {
        // Handle error
        throw new Error('Network response was not ok.');
      }
    })
    .then(data => {
      // Handle data from the response
      console.log(data);
      console.log(data.data);
      console.log(data.data[0]);
      console.log(data.data[0].projprod_Id);
      const id = data.data[0].projprod_Id; // Extracting the id from the response
      setId(id); // Set the id state variable
      uploadImageToAzure(data.data[0].projprod_Id);
      setShowSuccess(true);
      log.info('Created fundraiser with id', id)
    })
    .catch(error => {
      // Handle error
      log.error('Error:', error);
    });
  }
  
  
  return (
    <Modal
      id="modal"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered modal-lg h-auto success-modal modal-fullscreen-md-down"
      show={show}
      onHide={handleClose} // 1753 - 49 After creating the cause, and click outside modal, the modal get closed
      backdrop = "static" // prevent Closing by clicking outside the modal
      // keyboard = {false} // prevent closing by clicking esc button
    >
      
      {showForm1 && (
        <CreateFundraiserForm1 handleForm2={handleForm2} handleClose={handleClose} formData={formData} setFormData={setFormData} error={error} setError={setError} />
      )}

      {showForm2 && (
        <CreateFundraiserForm2 handleForm1={handleForm1} handleForm3={handleForm3} handleClose={handleClose} formData={formData} setFormData={setFormData} image={image} setImage={setImage} prodNameError={prodNameError} setProdNameError={setProdNameError} storyError={storyError} setStoryError={setStoryError} urlError={urlError} setUrlError={setUrlError} imageError={imageError} setImageError={setImageError} />
      )}

      {showForm3 && (
        <CreateFundraiserForm3 handleForm2={handleForm2} handleClose={handleClose} handleSuccess={handleSuccess} formData={formData} image={image} />
      )}

      {showSuccess && (
        <CreateFundraiserSuccess handleClose={handleClose} id={id}  />
      )}
      
    </Modal>
  );
};

export default CreateFundraiserModal;
