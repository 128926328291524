import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import img1 from "../../../assets/images/self.png";
import img2 from "../../../assets/images/others.png";
import { CloseButton } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

type Props = {
  handleForm2: () => void;
  handleClose: () => void;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  error: any;
  setError: React.Dispatch<React.SetStateAction<any>>;
}

const CreateFundraiserForm1: React.FC<Props> = ({ handleForm2, handleClose, formData, setFormData, error, setError }) => {
  const [t]=useTranslation("global")

  const [category, setCategory] = useState("1");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(null);
  };  

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
  };

  return (
    <form id="msform" className="row align-items-center px-5">
      <div className="col-12 col-md-4 d-none">
        <ul id='progressbar' className="text-center">
          <li className="active"> {t("create-title1")} </li>
          <li>{t("create-title2")}</li>
          {/* <li>{t("create-title3")}</li> */}
          <li>{t("create-title4")}</li>
        </ul>
      </div>
      <div className="col-12 col-md-12">
        <fieldset className="cardBlock py-2 ">
          <div className="d-flex justify-content-end">
            <CloseButton
              onClick={handleClose}
              variant="black" // Change color of the close button
              aria-label="Close" // Accessible label for screen readers
              disabled={false} // Set to true to disable the button
              className="mt-2"
            />
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center d-none">
            <div className="col-12 col-md-10 text-start">
              <h2 className={`fsTitle py-2`}>{t("whom-title")}</h2>
            </div>
          </div>
          <div className="pay-block row mb-3 justify-content-center align-items-center d-none">
            <div className={`payCard col-10 col-md-5 me-md-3   ${category === "1" ? "pay-select" : ""
              }`}>
              <input
                type="radio"
                className="btn-check"
                name="form-options"
                checked={category === "1"}
                value="1"
                id="self"
                onChange={(e) => setCategory(e.target.value)}
                required
              />
              <div className={`formCheck ps-0`}>

                <label
                  className="form-check-label d-flex justify-content-between align-items-center flex-column"
                  htmlFor="self"
                >
                  <div className="payImgs">
                    <img src={img1} alt="self" />
                  </div>
                  <div className="pay-radio pt-2">
                    <p>{t("wt1")}</p>
                  </div>
                </label>
              </div>
            </div>
            <div className={`payCard col-10 col-md-5 ${category === "2" ? "pay-select" : ""
              }`}>
                <input
                  type="radio"
                  className="btn-check"
                  name="form-options"
                  checked={category === "2"}
                  value="2"
                  id="others"
                  onChange={(e) => setCategory(e.target.value)}
                />
              <div className={`formCheck ps-0`}>

                <label
                  className="form-check-label d-flex justify-content-between align-items-center flex-column"
                  htmlFor="others"
                >
                  <div className="payImgs">
                    <img src={img2} alt="others" />
                  </div>
                  <div className="pay-radio pt-2">
                    <p>{t("wt2")}</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h2 className={`fsTitle py-1 mb-0  mt-3 col-12 col-md-10 text-start`}>
            {t("how-much")}
            </h2>
            <div className="col-12 col-md-10 mt-3 px-0 text-start">
              <div className="input-group d-none">
                <span className="input-group-text">€</span>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Amount (to the nearest euro)"
                  placeholder="Your starting goal"
                />
                <span className="input-group-text">.00</span>
              </div>
              <div className="input-group currency-input-wrapper">
                <span className="currency-symbol">{localStorage.getItem("currencySymbol")}</span>
                <input
                  type="number"
                  className="bill-input ps-5 goal removeUpDown"
                  id="eventTitle"
                  placeholder={t("starting-goal")}
                  name="targetAmount"
                  value={formData.targetAmount}
                  onChange={handleInputChange}
                  maxLength={6}
                  required
                  onInput={handleInput}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                    }
                  }}
                  
                />
              </div>
              {error && <div className="text-danger ms-1">{error}</div>}
            </div>
            
            <div className="col-12 col-md-10">
              <div className="detail-head py-3">
                <h3 className={`donateTitle my-3 text-start d-none`}>
                {t("fee")}
                </h3>
                <div className="donateDescription ">
                  <p className="d-none">
                    <strong>€1,000</strong> {t("minus")} =
                    <strong> €969.75</strong> {t("your-acc")}
                  </p>
                  <div
                    className={`alert alert-info d-flex donateDescription align-items-center`}
                    role="alert"
                  >
                   <FontAwesomeIcon icon={faCircleInfo} className="me-2" />
                    <p className="mb-0">
                    {t("fee-det")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">

            <input
              type="button"
              name="next"
              className={`next actionButton w-auto px-3`}
              value={t("continue")}
              onClick={handleForm2}
            />
          </div>
        </fieldset>
      </div>
    </form>
  );
};

export default CreateFundraiserForm1;
