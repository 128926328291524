import React, { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
// import img2 from "../../assets/images/log.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faPenToSquare,
  faTrashCan,
  faBuildingColumns,
  faPlus,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { ShareModal } from "../Modals/ShareModal";
import { PaymentConnectModal } from "../Modals/PaymentConnectModal";
import { OfflineDonationModal } from "../Modals/OfflineDonationModal";
import { DeleteConfirmationModal } from "../Modals/DeleteConfirmationModal"; 
import { useTranslation } from "react-i18next";

import { Zoom,  toast } from 'react-toastify';
import DataTableComp from "./DataTableComp";
import axios from "axios";
import AzureStorageService from "../../azure/AzureStorage";
// import { encrypt, decrypt } from "../../crypto-encryption/cryptoUtils";
import log from '../../logger';

type Props = {
  fundraiserData: any;
  id: any;
  encryptedId: any;
}

const ProfileTab: React.FC<Props> = ({ fundraiserData, id, encryptedId }) => {
  const [t] = useTranslation("global")
  const notify = () => toast.info("Successfully saved..!", {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Zoom,
    });

  
  // const [isActive, setActive] = useState(false);
  // const [activeItem, setActiveItem] = useState(-1);
  // const encryptedId = encrypt(`${id}`);
  const [shows, setShow] = useState(false);
  const [show, setShows] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = React.useState([]);
  const [image, setImage] = React.useState(null);
  const [imageError, setImageError] = useState<string | null>(null);
  // const [documentUrls, setDocumentUrls] = React.useState(null);
  const [formData, setFormData] = useState<any>({
    target_price: fundraiserData.target_price,
    cause_name: fundraiserData.cause_name,
    video_Url: fundraiserData.video_Url ,
    cause_description: fundraiserData.cause_description
  });
  const maxNumber = 5;

  useEffect(() => {
    const checkVideoUrl = async () => {
      if(formData.video_Url==="undefined" || formData.video_Url === "null" || formData.video_Url===undefined || formData.video_Url === null) {
        setFormData({ ...formData, video_Url: "" });
      }
    };
    checkVideoUrl();
  }, [formData]);

  useEffect(() => {
    const loadImages = async () => {
      if (fundraiserData.documents_url) {
        const imageUrls = fundraiserData.documents_url.split(',').map(url => ({ dataURL: url, file: null }));
        // Fetch the actual files from the URLs
        for (let i = 0; i < imageUrls.length; i++) {
          const response = await fetch(imageUrls[i].dataURL);
          const blob = await response.blob();
          const file = new File([blob], `image_${i}.jpeg`, { type: blob.type });
          imageUrls[i].file = file;
        }
        setImages(imageUrls as never[]);
      }
    };
    loadImages();
  }, [fundraiserData.documents_url]);

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };
  const handleImageChange = (imgList: ImageListType) => {
    // data for submit
    // setImage(imgList as never[]);
    // console.log(imgList);
    // console.log(imgList as never[]);
    if (imgList.length > 0) {
      const imageFile = imgList[0].file;
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        const { width, height } = img;
        const tolerance = 20;
        if (
          width >= 470 - tolerance && width <= 470 + tolerance &&
          height >= 300 - tolerance && height <= 300 + tolerance
        ) {
          setImage(imgList as never[]);
          setImageError(null);
        } else {
          setImageError(`Image dimensions should be 470x300 pixels with a tolerance of ±20 pixels.`);
        }
      };
    } else {
      setImage([]);
      setImageError(null);
    }
  };

  const azureStorageService = new AzureStorageService();

  const uploadCoverImage = async () => {
    try {
      // const formData = new FormData();
      // formData.append('image', image[0].file);

      // const response = await fetch(`http://localhost:8080/api/fundraiser/save-image?id=${id}`, {
      //   method: 'POST',
      //   body: formData,
      // });

      // if (response.ok) {
      //   const imageUrl = await response.text();
      //   console.log('Image uploaded to Azure:', imageUrl);
      // } else {
      //   console.error('Failed to upload image to Azure');
      // }
      await azureStorageService.uploadCoverImage(image[0].file,id);
      log.info("image uploaded to azure for fundraiser: ", image[0].file,id);
    } catch (error) {
      log.error('Error uploading image to Azure:', error);
    }
  };

  const uploadDocuments = async () => {
  //   // const formData = new FormData();
  //   images.forEach((image, index) => {
  //       // formData.append('images', image.file);
  //       azureStorageService.uploadImageToDocuments(image.file, id).then(data => {
  //         console.log(data);
  //         // setDocumentUrls(...prevData,data)
  //       })
  //       .catch(error => {
  //         // Handle error
  //         console.error('Error:', error);
  //       });
  //       console.log("uploadedDocs",image.file, id);
        
  //   });

    try {
      const uploadPromises = images.map(image => 
        azureStorageService.uploadImageToDocuments(image.file, id)
      );

      // Await all upload promises
      const uploadedUrls = await Promise.all(uploadPromises);

      // Append new URLs to the documentUrls state
      log.info("All documents uploaded successfully:", uploadedUrls.join());
      return uploadedUrls.join();

      
    } catch (error) {
      log.error('Error uploading images:', error);
    }

    // try {
    //     const response = await fetch(`http://localhost:8080/api/fundraiser/save-documents?id=${id}`, {
    //         method: 'POST',
    //         body: formData
    //     });
    //     const data = await response.json();
    //     // Display uploaded image URLs on the frontend
    //     console.log(data);
    // } catch (error) {
    //     console.error('Error uploading images:', error);
    // }
  }
  // 1753 - 52 After creating fundraiser. In Dashboard. try to edit VideoURL, Image Link was accepting in Dashboard VideoURl
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if(name==="target_price") {

      if(/^-|\D/.test(value)) {
        return;
      }
      const numericValue = Number(value);
      if (numericValue <= 0) {
        setFormData((prevState) => ({...prevState, [name]: ""}));
        setGoalError("Goal amount must be greater that zero");
      } 
      else {
        setFormData((prevState) => ({...prevState, [name]: value}));
        setGoalError(null);
      }
      
    } else {

      setFormData((prevState) => ({...prevState, [name]: value}));

      if(name==="cause_name") {
        setProdNameError(null);
      }
      if(name==="video_Url") {
        setUrlError(null);
      }
    }
  };
    
//1753 - 53 in GOAL amount the increase and decrease while pressing navigation button 
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent increment or decrement on arrow keys
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }

    if (e.key === "e" || e.key === "E" || e.key === "Enter") {
      e.preventDefault();
    }

    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setStoryError(null);
  };

  const isValidUrl = (urlString: string):boolean => {
    try {
      const url = new URL(urlString);
      console.log("ValidUrl");
      const videoExtensions = /\.(mp4|webm|ogg)$/i;
      const validVideoHostnames = ['Youtube.com', 'youtu.be', 'Vimeo.com']

      return (
        (url.protocol === 'http:' || url.protocol === 'https:') &&
        (videoExtensions.test(url.pathname) || validVideoHostnames.some(host => url.hostname.includes(host)))
      );
    } catch (error) {
      console.log("InValidUrl");
      return false;
    }
  }

  const [urlError, setUrlError] = useState(null);
  const [goalError, setGoalError] = useState(null);
  const [prodNameError, setProdNameError] = useState(null);
  const [storyError, setStoryError] = useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    // console.log(formData);
    try {
      if(formData.target_price<=0) {
        setGoalError("Please enter a valid amount.");
        setIsLoading(false);
        return;
      }
      if(formData.target_price>99999.99) {
        setGoalError("Please enter an amount within 99999.99");
        setIsLoading(false);
        return;
      }
      if(formData.video_Url && !isValidUrl(formData.video_Url)) {
        setUrlError("Please enter a valid video url.");
        setIsLoading(false);
        return;
      }

      if(formData.target_price && formData.cause_name && formData.cause_description && formData.cause_description.trim(" ").length>0 && formData.cause_name.trim(" ").length>0) {
        const username = 'mfb_apikey';
        const apiKey = localStorage.getItem("apiKey");

        
        if(images!=null) {
          var documentUrls = await uploadDocuments();
        }

        if(image!=null) {
          await uploadCoverImage();        
        }

        const queryParams = new URLSearchParams({
          targetAmount: String(formData.target_price),
          prodName: String(formData.cause_name),
          videoUrl: String(formData.video_Url),
          description: String(formData.cause_description),
          documents_url: String(documentUrls),
          id: String(id),
          status: "true"
        });
        // console.log("docUrls:",documentUrls);
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/fundraiser/update?${queryParams}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
            }
          }
        );
        log.info("Fundraiser data updated successfully!");
        notify();
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setIsLoading(false);
        if(!formData.target_price) {
          setGoalError("Please enter a goal.");
        }      
        if(!formData.cause_description || formData.cause_description.trim(" ").length===0){
          setStoryError("Please enter a story for the fundraiser.");
        }
        if(!formData.cause_name || formData.cause_name.trim(" ").length===0){
          setProdNameError("Please enter a title for the fundraiser.");
        }
      }
      
           
    } catch (error) {
      log.error("Error updating fundraiser data:", error);
      setIsLoading(false);
    }
  }

  const calculatePercentage = (raised, goal) => {
    if(raised===0 || goal===0) {
      return 0;
    }
    if(raised>goal) {
      return 100;
    }
    const percentage = (raised / goal) * 100;
    return percentage;
  }

  return (


    <>
      <div className={`card `}>
        {/* <!--begin::Header--> */}
        <div className={` border-0 pt-2 `}>
          <h3 className="card-title align-items-start flex-column d-none">
            <span className="card-label fw-bolder text-dark fs-1">
              {t("fct2")}
            </span>
            <span className="text-muted mt-2 fw-bold fs-6">
              <span className="text-dark">{localStorage.getItem("currencySymbol")}{fundraiserData.collected_price} {t("raised-of")}</span>{localStorage.getItem("currencySymbol")}{fundraiserData.target_price}
              {t("goal")}
            </span>
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-light nav-fill">
              <li className="nav-item">
                <a
                  className="nav-link  py-2 px-4 fw-bolder me-2 active"
                  data-bs-toggle="tab"
                  href="#details"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="18"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M0 0H9C10.6569 0 12 1.34315 12 3V12H3C1.34315 12 0 10.6569 0 9V0Z"
                      fill="#D9D9D9"
                    />
                    <rect x="14" width="12" height="12" fill="#797979" />
                    <path
                      d="M14 14H23C24.6569 14 26 15.3431 26 17V26H17C15.3431 26 14 24.6569 14 23V14Z"
                      fill="#D9D9D9"
                    />
                    <rect y="14" width="12" height="12" fill="#797979" />
                  </svg>
                  &nbsp;&nbsp;{t("dashboard")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  py-2 px-4 fw-bolder me-2"
                  data-bs-toggle="tab"
                  href="#donations"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="18"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M25.6482 15.9821C26.2395 16.997 26.068 18.4225 25.2645 19.1694L19.5499 24.4891C18.4936 25.4698 17.2207 26.0001 15.9071 26.0001H8.66676H1.44446C0.645493 26.0001 0 25.1848 0 24.1755V20.5264C0 19.5172 0.645493 18.7019 1.44446 18.7019H3.10559L5.13235 16.6492C6.15701 15.6115 7.42994 15.0527 8.7435 15.0527H12.2779H13.0001H15.8891C16.688 15.0527 17.3335 15.8681 17.3335 16.8773C17.3335 17.8865 16.688 18.7019 15.8891 18.7019H13.0001H12.2779C11.8807 18.7019 11.5557 19.1124 11.5557 19.6141C11.5557 20.1159 11.8807 20.5264 12.2779 20.5264H17.7217L23.1249 15.4975C23.9284 14.7505 25.0569 14.9672 25.6482 15.9821ZM8.73898 18.7019H8.69836C8.7119 18.7019 8.72544 18.7019 8.73898 18.7019Z"
                      fill="#797979"
                    />
                    <path
                      d="M9.97068 5.71804C9.96797 5.79227 9.96797 5.86375 9.96797 5.93797V6.37782C9.96797 6.45204 9.96797 6.52352 9.97068 6.59774H9.53464C9.05526 6.59774 8.66797 6.99086 8.66797 7.47744C8.66797 7.96403 9.05526 8.35714 9.53464 8.35714H10.3011C11.1028 10.6636 13.2721 12.3158 15.818 12.3158H16.468C16.9473 12.3158 17.3346 11.9227 17.3346 11.4361C17.3346 10.9495 16.9473 10.5564 16.468 10.5564H15.818C14.2498 10.5564 12.8876 9.6657 12.1915 8.35714H15.6013C16.0807 8.35714 16.468 7.96403 16.468 7.47744C16.468 6.99086 16.0807 6.59774 15.6013 6.59774H11.7067C11.704 6.52627 11.7013 6.45204 11.7013 6.37782V5.93797C11.7013 5.86375 11.704 5.78952 11.7067 5.71804H15.6013C16.0807 5.71804 16.468 5.32493 16.468 4.83835C16.468 4.35176 16.0807 3.95865 15.6013 3.95865H12.1915C12.8876 2.65009 14.2498 1.7594 15.818 1.7594H16.468C16.9473 1.7594 17.3346 1.36628 17.3346 0.879699C17.3346 0.393116 16.9473 0 16.468 0H15.818C13.2721 0 11.1028 1.65219 10.3011 3.95865H9.53464C9.05526 3.95865 8.66797 4.35176 8.66797 4.83835C8.66797 5.32493 9.05526 5.71804 9.53464 5.71804H9.97068Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                  &nbsp;&nbsp;{t("donation")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className="tab-content mt-2" id="myTabTables1">
            {/* <!--begin::Tap pane--> */}
            <div
              className="tab-pane fade active show "
              id="details"
              role="tabpanel"
              aria-labelledby="details"
            >
              {/* <!--begin::Table--> */}

              <div className="panel">
                <form className="py-4 form--disabled form">
                  <div className="dash-block ">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <h4 className="dash-title mb-0 ">{t("dashboard")}</h4>
                      <div className="dash-items d-flex mt-sm-0">
                        <button
                          className="p-2 px-3 btn btn-outline-success me-2 js-toggleForm d-none"
                          type="button"
                          id="liveToastBtn"
                        >
                          {t("edit")} &nbsp;
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className=" p-2 px-3 btn btn-outline-danger"
                        
                          onClick={(e) => {
                            e.preventDefault();
                            setShowDelete(true);
                          }}
                        >
                          {t("delete")} &nbsp;
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100 me-3 my-4">
                        
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-dark">
                            <div
                              className="progress-bar bg-dark"
                              role="progressbar"
                              style={{ width: (calculatePercentage(fundraiserData.collected_price,fundraiserData.target_price)+"%") }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            {Math.round(calculatePercentage(fundraiserData.collected_price,fundraiserData.target_price))}%
                          </span>
                        </div>
                      </div>
                  <div className="d-flex justify-content-between align-items-center flex-wrap pb-3">
                    <p className="profile-needed-money mb-0">
                      <span className="raised">{localStorage.getItem("currencySymbol")}{Number(fundraiserData.collected_price).toFixed(2)}</span>&nbsp; {t("raised-of")}<strong>{localStorage.getItem("currencySymbol")}{Number(fundraiserData.target_price).toFixed(2)} {t("goal")}</strong>
                    </p>
                    <a
                      className="btn btn-outline-primary mt-2 mt-sm-0 d-none"
                      href="#staticBackdrop"
                      onClick={() => setShows(true)}
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faBuildingColumns}
                          className="me-2"
                        />
                      </span>
                      {t("bank-details")}
                    </a>
                  </div>

                  <div className="row mb-2">
                    <label
                      htmlFor="inputGoal"
                      className="col-sm-3 col-form-label mt-4">
                      {t("goal")}*
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control bill-input goal removeUpDown"
                        id="inputGoal"
                        defaultValue={fundraiserData.target_price}
                        name="target_price"
                        value={formData.target_price}
                        onChange={handleInputChange}
                        maxLength={6}
                        onKeyDown={handleKeyDown}
                      />
                      {goalError && <div className="text-danger ms-1">{goalError}</div>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label
                      htmlFor="inputTitle"
                      className="col-sm-3 col-form-label mt-1"
                    >
                      {t("title")}*
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control bill-input py-3"
                        id="inputTitle"
                        defaultValue={fundraiserData.cause_name}
                        name="cause_name"
                        value={formData.cause_name}
                        onChange={handleInputChange}
                        maxLength={255}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {prodNameError && <div className="text-danger ms-1">{prodNameError}</div>}
                    </div>
                  </div>


                  <div className="row my-3">
                    <div className="upload__btn-box col-12 ">
                      <ImageUploading value={image} onChange={handleImageChange}>
                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                          // write your building UI
                          <div className="upload__image-wrapper row ">
                            <div className="col-sm-3 d-flex justify-content-between align-items-center d-sm-block">
                              <p className="mb-0 mb-sm-2">{t("cover-image")}</p>
                              <div className="d-flex">
                                <button
                                  type="button"
                                  onClick={onImageUpload}
                                  className="btn btn-dark cus-btn rounded-circle me-3"
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </button>
                                {imageList[0] && (
                                  <button
                                    onClick={onImageRemoveAll}
                                    className="btn btn-danger cus-btn rounded-circle"
                                    type="button"
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} />
                                  </button>
                                )}
                                
                              </div>
                            </div>
                            <div className="col-sm-9 ">
                              <div className="detail-shadow ms-2">
                                <div className="row  p-3 ">
                                  {imageList.map((image, index) => (
                                    <div
                                      key={index}
                                      className="image-item col-12 "
                                    >
                                      <img
                                        src={
                                          !image.dataURL
                                            ? 'toAbsoluteUrl("/media/illustrations/f2.png")'
                                            : image.dataURL
                                        }
                                        alt=""
                                        width="100%"
                                        height="250"
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                      {imageError && <div className="text-danger">{imageError}</div>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label
                      htmlFor="inputPassword3"
                      className="col-sm-3 col-form-label"
                    >
                      {t("story")}*
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        id="story"
                        style={{ height: "160px", fontSize: "13px" }}
                        className="form-control detail-shadow p-2  w-100 p-3"
                        defaultValue={fundraiserData.cause_description}
                        name="cause_description"
                        value={formData.cause_description}
                        onChange={handleTextAreaChange}
                      />
                      {storyError && <div className="text-danger ms-1">{storyError}</div>}
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      htmlFor="inputPassword3"
                      className="col-sm-3 col-form-label py-0"
                    >
                      {t("video-url")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control bill-input py-3"
                        id="inputPassword3"
                        defaultValue={fundraiserData.video_Url}
                        name="video_Url"
                        value={formData.video_Url}
                        onChange={handleInputChange}
                        maxLength={255}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {urlError && <div className="text-danger ms-1">{urlError}</div>}
                    </div>
                  </div>
                  <div className="row my-3 upload__box justify-content-between">
                    <div className="upload__btn-box col-12 ">

                      <p>{t("documents")}</p>
                      <div className="App">
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper row flex-column-reverse flex-sm-row">
                              <div className="col-sm-3">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                  className="btn btn-dark mb-3 px-2 w-100"
                                >
                                  {t("upload-images")}
                                </button>
                                  {imageList[0] && 
                                    <button
                                      onClick={onImageRemoveAll}
                                      className="btn btn-danger w-100"
                                      type="button"
                                    >
                                      {t("delete-all")}
                                    </button>
                                  }
                                
                              </div>
                              <div className="col-sm-9 ">
                                <div className="row p-3 detail-shadow ms-sm-3">
                                  {imageList.map((image, index) => (
                                    <div
                                      key={index}
                                      className="image-item col-sm-6 position-relative mb-3"
                                    >
                                      <img
                                        src={
                                          !image.dataURL
                                            ? "/media/illustrations/f2.png"
                                            : image.dataURL
                                        }
                                        alt=""
                                        width="100%"
                                        height="130"
                                      />
                                      <div className="image-item__btn-wrapper d-flex position-absolute top-0 end-0">
                                        <button
                                          onClick={() => onImageUpdate(index)}
                                          className="btn btn-dark  p-0 rounded-circle sm-cus-btn me-3"
                                          type="button"
                                        >
                                          <FontAwesomeIcon icon={faPencil} />
                                        </button>
                                        <button
                                          onClick={() => onImageRemove(index)}
                                          className="btn btn-danger  p-0 rounded-circle sm-cus-btn"
                                          type="button"
                                        >
                                          <FontAwesomeIcon icon={faTrashCan} />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-6">
                    <button
                 type="button"
                 onClick={handleSubmit}
                      className="btn btn-dark px-6 rounded-pill"
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ):(t("save-changes"))}
                      
                    </button>
                  </div>
                </form>
              </div>

              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Tap pane--> */}

            {/* <!--begin::Tap pane--> */}
            <div
              className="tab-pane fade "
              id="donations"
              role="tabpanel"
              aria-labelledby="details"
            >
              <div className={`card `}>
                {/* begin::Table */}

                <div className="don-block py-3">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h4 className="don-title">{t("donation")}</h4>
                    <a
                      className="don-link ms-4 btn btn-outline-primary mt-2 mt-sm-0 d-none"
                      onClick={() => setShowModal(true)}
                      href="#donations"
                      role="button"
                      aria-controls="donations"
                    >
                      {t("offline-don")}
                      <span>
                        <FontAwesomeIcon icon={faPlus} className="ps-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="goal-block bg-info-subtle d-flex align-items-center justify-content-between py-3 px-3 mb-3 flex-wrap">
                  <p className="goal-con mb-0 ">
                    {t("you-have")}<strong> {localStorage.getItem("currencySymbol")}{Number(fundraiserData.collected_price).toFixed(2)}</strong> {t("so-far")}
                  </p>
                  <a
                    href="#staticBackdrop"
                    onClick={() => setShow(true)}
                    className="btn btn-dark  rounded-pill goal-link mt-2 mt-sm-0 px-4"
                  >
                    {t("sb")}
                    <span>
                      &nbsp; <FontAwesomeIcon icon={faShareNodes} />
                    </span>
                  </a>
                </div>

                <div className="search-block d-flex d-none">
                  {" "}
                  <label className="form-label d-none">Default input</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("search")}
                  />
                </div>

               
                 <DataTableComp id={id} fundraiserName={fundraiserData.cause_name}/>
              </div>
            </div>
            {/* <!--end::Tap pane--> */}
          </div>
        </div>
      </div>

      <DeleteConfirmationModal show={showDelete} handleClose={() => setShowDelete(false)} id={id} />          
      <ShareModal show={shows} handleClose={() => setShow(false)} id={encryptedId} />
      <PaymentConnectModal show={show} handleClose={() => setShows(false)} />
      <OfflineDonationModal show={showModal} handleClose={() => setShowModal(false)} />
      
    </>
  );
};

export default ProfileTab;
