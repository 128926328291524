import FeatureCard from "./FeatureCard"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import log from '../../logger';

const FeatureSection = () => {
  const[t]=useTranslation("global")
  
  const [fundraisers, setFundraisers] = useState(null);
  const organizationId = localStorage.getItem("organizationId");

  useEffect(() => {
    const fetchFundraisersData = async () => {
      try {
        const username = 'mfb_apikey';
        const apiKey = localStorage.getItem("apiKey");
        // const base64Credentials = btoa(`${username}:${apiKey}`);

        // Set headers for the request
        // const headers = {
        //   'Content-Type': 'application/json',
        //   'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        // };
        fetch(`${process.env.REACT_APP_API_URL}/api/fundraiser/organization/${organizationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
          }
        }).then(response => {
          if(response.ok) {
            return response.json()
          } else {
            // Handle error
            throw new Error('Network response was not ok.');
          }
        }).then(data => {
          setFundraisers(data.data);
          log.info('Retrieved fundraisers for organization with id ', organizationId);
        })
        .catch(error => {
          // Handle error
          // console.error('Error:', error);
          log.error(error);
        });
        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_URL}/api/fundraiser/organization/${organizationId}`,
        //   { headers: headers }
        // );
        // setFundraisers(response.data);
        // console.log(response);
        // console.log(fundraisers);
      } catch (error) {
        log.error("Error fetching fundraisers:", error);
      }
    };

    fetchFundraisersData();
  }, [organizationId]);

  return (
    <>
      {fundraisers && 
        <section className="feature-section p-3 p-md-5">
          <h1 className="fund-rising pt-0">{t("fs")}</h1>
          <div className="container-fluid">
            <FeatureCard fundraisers={fundraisers}/>
          </div>
        </section>
      }
    </>
    
  
  )
}

export default FeatureSection