import React, { useEffect, useState } from "react";
import { ShareModal } from "../Modals/ShareModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import youtube from "../../assets/images/youtube (2).png";
import DonCard from "./DonCard";
import axios from "axios";
import log from '../../logger';

type Props = {
  fundraiserData: any;
  id: any;
  decryptedApiKey: any;
  currencySymbol: any;
};

const DetailSide: React.FC<Props> = ({ fundraiserData, id, decryptedApiKey, currencySymbol }) => {
  const [show, setShow] = useState(false);
  const [t] = useTranslation("global");
  const organizationId = localStorage.getItem("organizationId");
  const [donation, setDonation] = useState([]);
  const [totalDonations, setTotalDonation] = useState();

  const calculatePercentage = (raised, goal) => {
    if (raised === 0 || goal === 0) {
      return 0;
    }
    if (raised > goal) {
      return 100;
    }
    const percentage = (raised / goal) * 100;
    return percentage;
  };

  const isValidUrl = (urlString) => {
    try {
      const url = new URL(urlString);
      console.log("ValidUrl");
      return url.protocol && url.hostname && url.pathname !== undefined;
    } catch (error) {
      console.log("InValidUrl");
      return false;
    }
  }

  useEffect(() => {
    const fetchDonorsData = async () => {
      console.log("Fundraiser ID - Donation: ", fundraiserData.projprod_Id);
      try {
        const username = 'mfb_apikey';
        const apiKey = decryptedApiKey;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/donors/${fundraiserData.projprod_Id}`,
          { headers: headers }
        );
        // const fundraiser = response.data.object.data[0];
        setDonation(response.data.data);
        setTotalDonation(response.data.total_list_count);
        console.log(response.data);
        log.info('Retrieved donations for fundraiser with id ',fundraiserData.projprod_Id);
      } catch (error) {
        log.error("Error fetching donor details:", error);
      }
    };

    fetchDonorsData();
  }, [fundraiserData.projprod_Id,decryptedApiKey]);

  return (
    <>
      <div className="card detail-shadow mb-3 pt-2">
        <div className="venue-detail px-3 px-md-8">
          <h5 className="venue-title fs-3">
            {/* {t("fct2")} */}
            {fundraiserData.cause_name}
          </h5>
          <p className="needed-money">{t("nos-supporter")}: {totalDonations}</p>
          <p className="needed-money">
            <span className="raised">{currencySymbol}{Number(fundraiserData.collected_price).toFixed(2)}</span>
            &nbsp;{t("raised-of")}
            <strong>
            {currencySymbol}{Number(fundraiserData.target_price).toFixed(2)} {t("goal")}
            </strong>
          </p>
          <div className="d-flex flex-column w-100 me-3 my-4">
            <div className="d-flex align-items-center">
              <div className="progress h-10px  w-100 bg-light-dark">
                <div
                  className="progress-bar bg-dark"
                  role="progressbar"
                  style={{
                    width:
                      calculatePercentage(
                        fundraiserData.collected_price,
                        fundraiserData.target_price
                      ) + "%",
                  }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <span className="text-muted fs-7 fw-bold ps-3">
                {Math.round(calculatePercentage(
                  fundraiserData.collected_price,
                  fundraiserData.target_price
                ))}
                %
              </span>
            </div>
          </div>
          <button
            className="btn btn-dark w-100 mb-3 rounded-pill"
            onClick={() => setShow(true)}
          >
            {t("sb")}
            <span>
              <FontAwesomeIcon icon={faShareNodes} className="ms-3" />
            </span>
          </button>
          <button
            className="btn btn-dark w-100 mb-3 rounded-pill"
            onClick={()=>window.open(`${process.env.REACT_APP_PAYMENT_URL}/OVD.jsf?orgid=${organizationId}&projectId=${fundraiserData.project_Id}&projProdId=${fundraiserData.projprod_Id}&cfd=beta`)}
          >
            {t("db")}
            <span>
              <FontAwesomeIcon icon={faCircleRight} className="ms-3" />
            </span>
          </button>
        </div>
      </div>
      <DonCard id={fundraiserData.projprod_Id} donation={donation} currencySymbol={currencySymbol} />
      <div className="card detail-shadow pt-2 mt-3">
        {isValidUrl(fundraiserData.video_Url) && (
          <div className="venue-detail px-3 px-md-8">
            <h5 className="venue-title mb-0">Youtube URL</h5>

            <div className="yout-img  py-2">
              <a
                href={fundraiserData.video_Url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="ripple-1"></div>
                <div className="ripple-2"></div>
                <div className="ripple-3"></div>
                <div className="ripple-4"></div>
                <div className="ripple-5"></div>

                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
        )}
      </div>

      <ShareModal
        show={show}
        handleClose={() => setShow(false)}
        id={id}
      />
    </>
  );
};

export default DetailSide;
