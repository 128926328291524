import DataTable, { TableColumn } from "react-data-table-component";
import carson from "../../assets/profile-img/carson.png";
import fran from "../../assets/profile-img/fran.png";
import jie from "../../assets/profile-img/jie.png";
import anika from "../../assets/profile-img/anika.png";
import miron from "../../assets/profile-img/miron.png";
import user from "../../assets/profile-img/user.png";
import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faTrashCan,
  faFilePdf,
  faFileExcel,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import log from '../../logger';
// import { timeStamp } from "console";


interface DataRow {
  name: string;
  amount: string;
  transactiondate: number;
  Mode: boolean;
  // Email: string;
  // Location: string;
  // PhoneNumber: string;
  // SignedUp: string;
}

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    PDF
  </Tooltip>
);
const excelTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Excel
  </Tooltip>
);

function DataTableComp({ id, fundraiserName }) {
  const getAvatarImage = (name: string) => {
    switch (name) {
      case "Carson Darrin":
        return carson;
      case "Fran Perez":
        return fran;
      case "Jie Yan Song":
        return jie;
      case "Anika Visser":
        return anika;
      case "Miron Vitold":
        return miron;
      default:
        return user; // Default image if the name doesn't match any case
    }
  };

  // const [status , setStatus] = useState(false)
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState();
  const [rows, setRows] = useState<DataRow[]>([
    {
      name: "Carson Darrin",
      amount: "800",
      transactiondate: 2,
      Mode: true,
      // Email: "Carson.Darrin@gmail.com",
      // Location: "Cleveland, Ohio, USA",
      // PhoneNumber: "304-428-3097",
      // SignedUp: "01/02/2024",
    },
    {
      name: "Fran Perez",
      amount: "500",
      transactiondate: 4,
      Mode: false,
      // Email: "Fran.Perez@gmail.com",
      // Location: "Atlanta, Georgia, USA",
      // PhoneNumber: "712-351-5711",
      // SignedUp: "31/01/2024",
    },
    {
      name: "Jie Yan Song",
      amount: "1200",
      transactiondate: 5,
      Mode: false,
      // Email: "Jie.Yan.Song@gmail.com",
      // Location: "North Canton, Ohio, USA",
      // PhoneNumber: "770-635-2682",
      // SignedUp: "31/01/2024",
    },
    {
      name: "Anika Visser",
      amount: "600",
      transactiondate: 8,
      Mode: true,
      // Email: "Anika.Visser@gmail.com",
      // Location: "Madrid, , Spain",
      // PhoneNumber: "908-691-3242",
      // SignedUp: "31/01/2024",
    },
    {
      name: "Miron Vitold",
      amount: "600",
      transactiondate: 8,
      Mode: false,
      // Email: "Miron.Vitold@gmail.com",
      // Location: "San Diego, California, USA",
      // PhoneNumber: "972-333-4106",
      // SignedUp: "30/01/2024",
    },
  ]);

  // Function to format date with timestamp
  // 1753 - 08 transaction date(timestamp CET)should be shown in Donations tab throughtout the application
const formatDateWithTimestamp = (timeStamp) => {
  const date = new Date(timeStamp);
  const formattedDate = date.toLocaleString("en-GB", {
    timeZone: "Europe/Brussels",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} CET`;
};

  // const removeTimeFromDate = (dateTime: string): string => {
  //   const date = new Date(dateTime);
  //   return date.toISOString().split('T')[0];
  // };

  const cols: TableColumn<DataRow>[] = [
    {
      name: "name",
      selector: (row) => row.name,
      cell: (row, rowIndex) => (
        <div className="cust-container d-flex align-items-center">
          <div className="cust-logo me-4">
            <img
              src={getAvatarImage(row.name)}
              alt="not found"
              className="logo-img"
            />
          </div>
          <div className="cust-name">
            <h6 className="payee-name">{row.name}</h6>
            <p className="mb-0 ">
              <span className="paided">
                {localStorage.getItem("currencySymbol")}
                {Number(row.amount).toFixed(2)}
              </span>{" "}
            <span className="d-none">{row.transactiondate} CET</span>  
              <span
                className={
                  row.Mode
                    ? "badge rounded-pill text-bg-primary p-2 d-none"
                    : "badge rounded-pill text-bg-warning p-2 d-none"
                }
              >
                {row.Mode ? "Online" : "Online"}
              </span>
            </p>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Amount",
    //   selector: (row) => row.amount,
    //   cell: (row, rowIndex) => (
    //     <div>

    //       <p className="mb-0">
    //         <span className="paided">
    //           {localStorage.getItem("currencySymbol")}
    //           {Number(row.amount).toFixed(2)}
    //         </span>{" "}

    //       </p>
    //     </div>
    //   ),
    // },
    {
      name: "Date",
      selector: (row) => row.transactiondate,
      cell: (row, rowIndex) => (
        <div>
          <p className="mb-0" >{formatDateWithTimestamp(row.transactiondate)}</p>
        </div>
      ),
    },
    // {
    //   name: "Location",
    //   selector: (row) => row.Location,
    //   cell: (row, rowIndex) => (
    //     <div>
    //       {editingRowIndex === rowIndex ? (
    //         <input
    //           className="edit-input in-location"
    //           type="text"
    //           value={row.Location}
    //           onChange={(e) =>
    //             handleFieldChange(e.target.value, rowIndex, "Location")
    //           }
    //         />
    //       ) : (
    //         row.Location
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Phone Number",
    //   selector: (row) => row.PhoneNumber,
    //   cell: (row, rowIndex) => (
    //     <div>
    //       {editingRowIndex === rowIndex ? (
    //         <input
    //           className="edit-input in-phonenumber"
    //           type="text"
    //           value={row.PhoneNumber}
    //           onChange={(e) =>
    //             handleFieldChange(e.target.value, rowIndex, "PhoneNumber")
    //           }
    //         />
    //       ) : (
    //         row.PhoneNumber
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Signed Up",
    //   selector: (row) => row.SignedUp,
    //   cell: (row, rowIndex) => (
    //     <div>
    //       {editingRowIndex === rowIndex ? (
    //         <input
    //           className="edit-input in-signup"
    //           type="text"
    //           value={row.SignedUp}
    //           onChange={(e) =>
    //             handleFieldChange(e.target.value, rowIndex, "SignedUp")
    //           }
    //         />
    //       ) : (
    //         row.SignedUp
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Actions",
    //   cell: (_row, rowIndex) => (
    //     <div className="action d-flex justify-content-end">
    //       <button
    //         className="p-2 px-3 btn btn-outline-danger d-none"
    //         onClick={() => handleDelete(rowIndex)}
    //       >
    //         <FontAwesomeIcon icon={faTrashCan} />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const [searchValue, setSearchValue] = useState("");

  const filteredData = rows.filter((row) => { 
    const searchText = searchValue.toLowerCase();
    return (
      row.name.toLowerCase().includes(searchText) ||
      row.amount.toString().toLowerCase().includes(searchText)
      // row.Mode.toLowerCase().includes(searchText)
      // row.Location.toLowerCase().includes(searchText) ||
      // row.SignedUp.toLowerCase().includes(searchText)
    );
  });

  // const handleDelete = (rowIndex: number) => {
  //   const updatedRows = [...rows];
  //   updatedRows.splice(rowIndex, 1);
  //   setRows(updatedRows);
  // };
 // 1753 - 7 In Donwloaded pdf,excel Name,Amount(currency symbol),transaction date(timestamp CET)should be shown
  const handleExportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text(fundraiserName, 14, 15);//plan name
    doc.autoTable({
      head: [["Name", "Amount", "Transaction Date"]],
      body: filteredData.map((row) => [
        row.name,
        // row.amount,
        `${localStorage.getItem("currencySymbol")}${Number(row.amount).toFixed(2)}`,
        formatDateWithTimestamp(new Date(row.transactiondate).getTime()),
        // row.Email,
        // row.Location,
        // row.PhoneNumber,
        // row.SignedUp,
      ]),
      startY: 18,
    });
    // alert("Pdf Saved Successfully");
    doc.save(`${fundraiserName}.pdf`);//plan name
  };

  const [filename] = useState(`${fundraiserName}.xlsx`);//plan name xlsx

  const exportExcel = () => {
    const formattedData = filteredData.map((row) => ({
      name: row.name,
      // amount: row.amount,
      amount : `${localStorage.getItem("currencySymbol")}${Number(row.amount).toFixed(2)}`,
      transactionDate: formatDateWithTimestamp(new Date(row.transactiondate).getTime()),  
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "My Data");

    XLSX.writeFile(workbook, filename);
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px",
      },
    },
  };

  // function timeAgo(timestamp): string {
  //   const givenTime = new Date(timestamp).getTime();
  //   const currentTime = new Date().getTime();
  //   const diffInMs = currentTime - givenTime;

  //   const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  //   const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  //   const diffInWeeks = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 7));

  //   if (diffInHours < 24) {
  //     return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  //   } else if (diffInDays < 7) {
  //     return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  //   } else {
  //     return `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''} ago`;
  //   }
  // }


  useEffect(() => {
    const fetchDonorsData = async (index = page, size = perPage) => {
      console.log("Fundraiser ID-donation: ", id);
      try {
        const username = "mfb_apikey";
        const apiKey = localStorage.getItem("apiKey");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${username}:${apiKey}`)}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/donors/${id}?list_Limit=${size}&&page_index=${index}`,
          { headers: headers }
        );
        // const fundraiser = response.data.object.data[0];
        setRows(response.data.data);
        setTotalRows(response.data.total_list_count);
        console.log(response.data.data);
        console.log("total", response.data.total_list_count);
        console.log("page", page);
        log.info('Retrieved donations for fundraiser with id ', id);

      } catch (error) {
        log.error("Error fetching donor details:", error);
      }
    };
    fetchDonorsData();
  }, [id, page, perPage]);

  const handlePageChange = page => {
    // fetchDonorsData(page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchDonorsData(page, newPerPage);
    setPerPage(newPerPage);
  };

  return (
    <div className="page-container">
      <div className="table-head d-flex justify-content-between align-items-center py-3">
        
        {filteredData.length > 0 && (
          <div className="search-div col-6 position-relative">
          <input
            type="text"
            id="search-box"
            className="form-control bill-input py-2 in-border "
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Customer..."
          />
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="position-absolute top-0 end-0 mt-3 pt-1 pe-2"
          />
        </div>
        )}
        {totalRows !== 0 &&
          <div className="import-export">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <button
                className="btn rounded-circle btn-dark me-2 btn-size"
                onClick={handleExportToPDF}
              >
                <FontAwesomeIcon icon={faFilePdf} />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={excelTooltip}
            >
              <button
                className="btn rounded-circle btn-primary btn-size"
                onClick={exportExcel}
              >
                <FontAwesomeIcon icon={faFileExcel} />
              </button>
            </OverlayTrigger>
          </div>
        }

      </div>

      <DataTable
        columns={cols}
        data={filteredData}
        // selectableRows
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        // onChangePage={page => setPage(page)}
        customStyles={customStyles}
      />
    </div>
  );
}

export default DataTableComp;
