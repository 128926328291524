import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "./DeleteModal";
import { useState } from "react";
import log from '../../logger';
import axios from "axios";
import { useNavigate } from "react-router-dom";

type Props = {
    show: boolean;
    handleClose: () => void;
    id: number;
};

const DeleteConfirmationModal: React.FC<Props> = ({ show, handleClose, id }) => {
    const [t]=useTranslation("global")
    const [showDelete, setShowDelete] = useState(false);
    const navigate = useNavigate();

    const closeModal  = () => {
        navigate("/myfundraiser");
        setShowDelete(false);
    }

    const deleteCause = async () => {
        try {
            const username = 'mfb_apikey';
            const apiKey = localStorage.getItem("apiKey");

            await axios.delete(
              `${process.env.REACT_APP_API_URL}/api/fundraiser/delete/${id}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Basic ${btoa(`${username}:${apiKey}`)}`
                }
              }
            );

            setShowDelete(true);
            log.info('Deleted the fundraiser with id - ', id)
          } catch (error) {
            log.error("Error deleting fundraiser data:", error);
          }
    }

    return (

        <>
            <Modal
                id="kt_modal_create_app"
                tabIndex={-1}
                aria-hidden="true"
                dialogClassName="modal-dialog-centered  w-800px"
                show={show}
                backdrop="static"
                onHide={handleClose}
            >
                <div className="container pt-10">
                    <div className="modal-header py-2 ">
                        {/* begin::Close */}
                        <h5 className="modal-title text-center">{t("delete-title")}</h5>
                        <div className="d-flex justify-content-end border-0">

                            <div
                                className="btn btn-icon btn-sm btn-light-primary"
                                onClick={handleClose}
                            >
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                        <rect fill="#000000" id="Rectangle-185" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" id="Rectangle-185-Copy" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body pb-0 ">
                        <div className="row ">
                            <div className="col-12 pb-0 text-center px-2">
                                <p className="don-content">

                           {t("delete-con")}
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    <div className=" position-sticky bottom-0 start-0 p-3">
                        <div className="d-flex justify-content-center">
                            <button className="btn px-4 btn-outline-danger me-3" type="button"  onClick={deleteCause}>
                              {t("delete")}
                            </button>
                            <button
                                className="btn px-4 btn-outline-dark"
                                data-bs-dismiss="modal"
                                type="button"
                                onClick={handleClose}
                            >
                              {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <DeleteModal show={showDelete} handleClose={closeModal} />
        </>
    );
};

export { DeleteConfirmationModal };
